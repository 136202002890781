import {config} from '../config';
import httpBuildQuery from "http-build-query";
import BigNumber from 'bignumber.js';
function genUrl(token){
    var returnTo = "https://login.m4dsolutions.com/steam?client=" + config.clientID + "&token=" + token;
    var params = {
        'openid.ns': 'http://specs.openid.net/auth/2.0',
        'openid.mode': 'checkid_setup',
        'openid.return_to': returnTo,
        'openid.realm': 'https://login.m4dsolutions.com',
        'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
        'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select',
    }
    
    return config.steam_url + "?" + httpBuildQuery(params);
}

var convert = new BigNumber('76561197960265728');
var regexs = {
    REGEX_STEAMID64: /^[0-9]{17}$/,
    REGEX_STEAMID:   /^STEAM_[0-5]:[01]:\d+$/,
    REGEX_STEAMID3:  /^\[U:1:[0-9]+\]$/,
}
function to64(steamid){
    if(!steamid || typeof steamid !== "string") {
        return false;
    }
    else if(isSteamID3(steamid)) {
        steamid = fromSteamID3(steamid);
    }
    else if(!isSteamID(steamid)) {
        return steamid
    }

    var split = steamid.split(":"),
        v = convert,
        z = split[2],
        y = split[1];

    if(z && y) {
        return v.plus(z*2).plus(y).toString();
    }
    return false;
}
function to32(steamid64){
    if(!steamid64 || typeof steamid64 !== "string") {
        return false;
    }
    else if(isSteamID3(steamid64)) {
        return fromSteamID3(steamid64);
    }
    else if(!isSteamID64(steamid64)) {
        return steamid64;
    }

    var v = convert,
        w = new BigNumber(steamid64),
        y = w.mod(2).toString();

    w = w.minus(y).minus(v);

    if(w < 1) {
        return false;
    }
    return "STEAM_0:" + y + ":" + w.dividedBy(2).toString();
}
function fromSteamID3(steamid3) {
    var split = steamid3.split(":");
    var last  = split[2].substring(0, split[2].length - 1);

    return "STEAM_0:" + (last % 2) + ":" + Math.floor(last / 2);
}
function isSteamID3(id){
    if(!id || typeof id !== "string") {
        return false;
    }
    return regexs.REGEX_STEAMID3.test(id);
}
function isSteamID64(id) {
    if(!id || typeof id !== "string") {
        return false;
    }
    return regexs.REGEX_STEAMID64.test(id);
}
function isSteamID(id){
    if(!id || typeof id !== "string") {
        return false;
    }
    return regexs.REGEX_STEAMID.test(id);
}
export const steam = {
    url: genUrl,
    to32,
    to64
};