import React from 'react';

import {
	Row,
	Col
} from 'reactstrap';

class PageTitle extends React.Component {
	
	render(){
		return (
			<Row className="bg-title">
				<Col lg="3" md="4" sm="4" xs="12">
					<h4 className="page-title">{this.props.children}</h4>
				</Col>
			</Row>
		)
	}
}

export {PageTitle};