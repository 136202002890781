import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../_helpers';

export const SteamRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const {auth} = store.getState();
        if(auth.user && !auth.user.steamid){
            return <Component {...props} />
        }else{
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
    }} />
)