import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { manageActions } from '../_actions';
import { Scrollbars } from 'react-custom-scrollbars';
import './Sidebar.css';

import {
	Nav,
	NavItem,
} from 'reactstrap';

class Sidebar extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			ignoreFetch: true
		}
	}
	componentDidMount(){
        const { ignoreFetch } = this.state;
        if(ignoreFetch && Object.keys(this.props.data).length !== 0){
            this.setState({ignoreFetch:false});
            return;
        }
        this.props.dispatch(manageActions.getServers());
	}

	render(){
		const { data, user } = this.props;
		const sData = Object.values(data || {});
		return (
            <div className="navbar-default sidebar hide-scrollbar" role="navigation">
                <div className="sidebar-nav navbar-collapse">
					<Scrollbars style={{width: 220, height:"90vh"}}>
						<Nav id="side-menu">
							<NavItem className="nav-small-cap m-t-10">-- Management</NavItem>
							<NavItem><NavLink exact to="/" ><i className="fa fa-home fa-fw"/> <span className="hide-menu">Home</span></NavLink></NavItem>
							<NavItem><NavLink to="/key-management" ><i className="fa fa-key fa-fw"/> <span className="hide-menu">Key Management</span></NavLink></NavItem>
							<NavItem><NavLink to="/upgrades" ><i className="fa fa-angle-double-up fa-fw"/> <span className="hide-menu">Upgrades</span></NavLink></NavItem>
							<NavItem className="nav-small-cap m-t-10">-- Servers</NavItem>
							{sData.map(function(sD, idx){
								return <NavItem key={idx}><NavLink to={"/server/"+sD._id} ><i className="fa fa-server fa-fw"/> <span className="hide-menu">{sD.name}
								{(() => {if(sD.steamid !== user.steamid){return <i className="fa fa-eye read-eye"></i>}})()}</span></NavLink></NavItem>
							})}
						</Nav>
					</Scrollbars>
                </div>
            </div>
		)
	}
}
function mapStateToProps(state) {
	const { data } = state.manage.servers;
	const { user } = state.auth;
    return {
		data,
		user
    };
}

const cSidebar = withRouter(connect(mapStateToProps)(Sidebar));
export { cSidebar as Sidebar }; 