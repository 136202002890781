import { logsConstants } from '../_constants';
import { logsService } from '../_services';
import { alertActions } from './';

export const logActions = {
    getLogs,
    getCategories,
    getSchema,
    searchLogs
};

function getLogs(id,options){
	return dispatch => {
		dispatch(request());
        
        logsService.getLogs(id,options)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_LOGS_REQUEST } }
    function success(data) { return { type: logsConstants.GET_LOGS_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_LOGS_FAILURE, error } }
}

function getCategories(id){
	return dispatch => {
		dispatch(request());
        
        logsService.getCategories(id)
            .then(
                data => {
                    var mapped = data.map((obj,idx) => {
                        return obj._id;
                    });
                    dispatch(success(mapped));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_CATEGORIES_REQUEST } }
    function success(data) { return { type: logsConstants.GET_CATEGORIES_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_CATEGORIES_FAILURE, error } }
}

function getSchema(id){
	return dispatch => {
		dispatch(request());
        
        logsService.getSchema(id)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_SCHEMA_REQUEST } }
    function success(data) { return { type: logsConstants.GET_SCHEMA_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_SCHEMA_FAILURE, error } }
}

function searchLogs(id,query){
    return getLogs(id,{search:query});
}