import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { loadUser } from 'redux-oidc';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers';
import userManager from './userManager';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    //export const store = createStore(rootReducer,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  )
);
if (userManager) {
  loadUser(store, userManager);
}
