//import { authHeader } from '../_helpers';
import { parseJwt, authHeader, handleResponse } from '../_helpers';
import { config } from '../config';
import userManager from '../_helpers/userManager';
import { userConstants } from '../_constants';

export const userService = {
    auth,
    logout,
    validate,
    getUser
};

function auth(token){
	return new Promise((resolve,reject) => {
        const user = parseJwt(token);
        user.hasPurchased = false;
        if(user.gms_purchases){
            for(var i=0;i<user.gms_purchases.length;i++){
                if(user.gms_purchases[i] == 2310){
                    user.hasPurchased = true;
                    break;
                }
            }
        }

        localStorage.setItem("m_auth", token);
		localStorage.setItem('m_user', JSON.stringify(user));
		resolve(user);
	});
}

function getUser(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${config.api_url}/user`,requestOptions).then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('m_auth');
    //localStorage.removeItem('m_user');

}

function validate(token){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token})
    };

    return fetch('https://login.m4dsolutions.com/validate',requestOptions)
        .then(response => {
            if(!response.ok){
                return Promise.reject(response.statusText)
            }
            
            return response.json();
        })
        .then(json => {
            return json["success"];
        });
}