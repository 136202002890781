import React from 'react';
import { Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import {config} from '../../config';
import Recaptcha from "react-recaptcha";
import { manageActions } from '../../_actions';
import { connect } from 'react-redux';
import './AddServer.css';

class AddServer extends React.Component {
	render(){
		const { requestAccess } = this.props;
		return (
			<div>
			<Formik initialValues = {
				{
					serverid: '',
					password: '',
					recaptcha: '',
				}
			}
			validate = {
				values => {
					let errors = {};
					if (!values.serverid) {
						errors.serverid = 'Server ID cannot be empty!';
					}

					if(!values.password){
						errors.password = "Password cannot be empty!";
					}

					if(!values.recaptcha){
						errors.recaptcha = "reCaptcha needs completing!"
					}
					return errors;
				}
			}
			onSubmit = {
				(
					values, {
						setSubmitting,
						setErrors /* setValues and other goodies */
					}
				) => {
					requestAccess(values);
				}
			}
			render = {
				({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue
				}) => ( 
					<form onSubmit={handleSubmit}>
						<Row>
							<Col lg="1" md="12"><label className="control-label">Server ID:</label></Col>
							<Col lg="3" md="12">
								<input type="text" name="serverid" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.serverid}/>
								{touched.serverid && errors.serverid && <div>{errors.serverid}</div>}
							</Col>
							<Col lg="1" md="12"><label className="control-label">Password:</label></Col>
							<Col lg="3" md="12">
								<input type="password" name="password" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.password}/>
								{touched.password && errors.password && <div>{errors.password}</div>}
							</Col>
							<Col lg="2" md="12" className="text-center small-captcha">
								<Recaptcha
									sitekey={config.recaptcha_site_key}
									theme="dark"
									render="explicit"
									verifyCallback={(response) => {setFieldValue("recaptcha",response);}}
									onloadCallback={() => {}}
								/>
								{touched.recaptcha && errors.recaptcha && <div>{errors.recaptcha}</div>}
							</Col>
						</Row>
						<Row>
							<Col md="12" className="text-center">
								<button className="btn btn-info btn-block" type="submit" disabled={isSubmitting}>
									Submit
								</button>
							</Col>
						</Row>
					</form>
				)
			}
			/> 
			</div>
		)
	}
}

function mapStateToProps(state) {
    const { results } = state.logs;
    return {
        results
    };
}
function mapDispatchToProp(dispatch){
	return {
		requestAccess: (values) => {
			dispatch(manageActions.requestAccess(values.serverid,values.password,values.recaptcha));
		}
	}
}
const cAddServer = connect(mapStateToProps,mapDispatchToProp)(AddServer);
export { cAddServer as AddServer }; 