import React from 'react';
import { connect } from 'react-redux';
import { PageWrapper } from '../../_components';
import { Row, Col } from 'reactstrap';
//import { ServerConsole } from './ServerConsole';
import { LogBrowser } from './LogBrowser';
import { WebPermissions } from './WebPermissions';

class ServerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            server: {name:"",_id:"", steamid:""}
        }
    }

    componentDidMount(){
    }

    render() {
        const { id } = this.props.match.params;
        const { servers, user } = this.props;
        
        var server = this.state.server;
        if(id && servers[id]){
            server = servers[id];
        }
        return (
            <PageWrapper title={"Server Viewer - " + server.name}>
				<Row>
                    <Col md="12">
                        <LogBrowser serverID={id} />
                    </Col>
				</Row>
                {(()=>{
                    if(server.steamid === user.steamid){
                        return (
                            <Row>
                                <Col md="12">
                                    <WebPermissions serverID={id} server={server}/>
                                </Col>
                            </Row>
                        )
                    }
                })()}
			</PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { data } = state.manage.servers;
    const { user } = state.auth;
    return {
        servers: data,
        user
    };
}

const cServerPage = connect(mapStateToProps)(ServerPage);
export { cServerPage as ServerPage }; 