import { authHeader, handleResponse } from '../_helpers';
import { config } from '../config';

export const planService = {
    getPlan,
};

function getPlan(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    
    return fetch(`${config.api_url}/plan`,requestOptions).then(handleResponse);
}