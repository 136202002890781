import React from 'react';
import { Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {config} from '../../config';
import Recaptcha from "react-recaptcha";
import { manageActions } from '../../_actions';
import './KeyAddServer.css';
class KeyAddServer extends React.Component {
	render() {
		const { addServer, loading } = this.props;
		return (
			<div>
			<Formik initialValues = {
				{
					server_name: '',
					recaptcha: '',
				}
			}
			validate = {
				values => {
					let errors = {};
					if (!values.server_name) {
						errors.server_name = 'Server Name cannot be empty!';
					}
		
					if(!values.recaptcha){
						errors.recaptcha = "reCaptcha needs completing!"
					}
					
					return errors;
				}
			}
			onSubmit = {
				(
					values, {
						setSubmitting,
						setErrors /* setValues and other goodies */
					}
				) => {
					addServer(values);
				}
			}
			render = {
				({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue
				}) => ( 
					<Row>
						<form onSubmit={handleSubmit}>
							<Col lg="12">
								<div className="form-group">
									<label htmlFor="server_name" className="control-label col-lg-12">Server Name:</label>
									<input type="text" id="server_name" name="server_name" className="form-control" placeholder="Enter Server Name" 
										onChange={handleChange} onBlur={handleBlur} value={values.server_name}/>
									{touched.server_name && errors.server_name && <div>{errors.server_name}</div>}
								</div>
								<div className="form-group inline-captcha">
									<label htmlFor="recaptcha" className="control-label col-lg-12">Captcha:</label>
									<Recaptcha
										sitekey={config.recaptcha_site_key}
										theme="dark"
										render="explicit"
										verifyCallback={(response) => {setFieldValue("recaptcha",response);}}
										onloadCallback={() => {}}
									/>
									{touched.recaptcha && errors.recaptcha && <div>{errors.recaptcha}</div>}
								</div>
								<button className="btn btn-info btn-block m-t-10" type="submit" disabled={loading}>
									Add Server
								</button>
							</Col>
						</form>
					</Row>
				)
			}
			/> 
			</div>
		)
	}
}
function mapStateToProps(state) {
    const { loading } = state.manage.servers;
    return {
        loading,
    };
}

function mapDispatchToProp(dispatch){
	return {
		addServer: (values) => {
			dispatch(manageActions.addServer(values.server_name,values.recaptcha));
		}
	}
}

const cKeyAddServer = connect(mapStateToProps,mapDispatchToProp)(KeyAddServer);
export { cKeyAddServer as KeyAddServer }; 