import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { Sidebar, PageTitle, AppAlert } from './';
import DocumentTitle from 'react-document-title';
import {
	Nav,
	Navbar,
	Row
} from 'reactstrap';

class PageWrapper extends React.Component {
	constructor(props){
		super(props);
		
		this.handleLogout = this.handleLogout.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.state = {
			pageWrapperStyle: {}
		}
	}
	
	componentDidMount(){
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentWillUnmount(){
		window.removeEventListener("resize", this.handleResize);
	}
	
	handleLogout(e){
		e.preventDefault();
		this.props.dispatch(userActions.logout());
	}

	handleResize(){
		var topOffset = 60;
		var height = (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;
		height = height - topOffset;
		if (height < 1) {
			height = 1;
		}
		if (height > topOffset) {
			this.setState({pageWrapperStyle: {minHeight: height +"px"}});
		}
		
	}

	render(){
		const { user } = this.props;
		const { pageWrapperStyle } = this.state;
		let alert = <div></div>;
		if(user && !user.hasPurchased){
			alert = <div className="alert alert-danger">You have not purchased mLogs. Please <a href="https://www.gmodstore.com/scripts/view/2310">click here</a> to purchase it.</div>
		}
		return (
			<DocumentTitle title={"mTracker | " + this.props.title}>
				<div id="wrapper">
					<Navbar className="navbar-static-top m-b-0">
						<div className="top-left-part"><a className="logo" href="/">
							<img src="assets/images/logo.png" alt="home" className="dark-logo"/>
							<span className="hidden-xs">
								<img src="assets/images/text.png" alt="home" className="dark-logo" />
							</span>
						</a></div>
						<Nav className="navbar-top-links navbar-right pull-right">
							<li className="dropdown">
								<a href="#user" className="dropdown-toggle profile-pic" data-toggle="dropdown">
									<img 
										src={user.avatar}
										alt="user-img" 
										width="36"
										className="img-circle"
									/>
									<span className="hidden-xs">
										{user.steam_name}
									</span>
								</a>
								<ul className="dropdown-menu dropdown-user animated slideInRight">
									<li><a href="#logout" onClick={this.handleLogout}><i className="fa fa-power-off"></i> Logout</a></li>
								</ul>
							</li>
						</Nav>
					</Navbar>
					<Sidebar />
					<div id="page-wrapper" style={pageWrapperStyle}>
						<div className="container-fluid">
							<PageTitle>
								{this.props.title}
							</PageTitle>
							<Row>
								{alert}
							</Row>
							<Row>
								<AppAlert />
							</Row>
							{this.props.children}
						</div>
					</div>
				</div>
			</DocumentTitle>
		)
	}
}
function mapStateToProps(state) {
    const { loggingIn, user } = state.auth;
    return {
		loggingIn,
		user
    };
}

const connectedPageWrapper = connect(mapStateToProps)(PageWrapper);
export { connectedPageWrapper as PageWrapper }; 