import { planConstants } from '../_constants';
import { planService } from '../_services';
import { alertActions } from './';

export const planActions = {
    getPlan
};

function getPlan(){
	return dispatch => {
		dispatch(request());
        
        planService.getPlan()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: planConstants.GET_PLAN_REQUEST } }
    function success(data) { return { type: planConstants.GET_PLAN_SUCCESS, data } }
    function failure(error) { return { type: planConstants.GET_PLAN_FAILURE, error } }
}