import React from 'react';
import { connect } from 'react-redux';
import { processSilentRenew } from 'redux-oidc';

class SilentPage extends React.Component {
	componentDidMount() {
        processSilentRenew();
	}

    render() {
        return (
            <div></div>
        )
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state;
    return {
        loggingIn
    };
}

const cSilentPage = connect(mapStateToProps)(SilentPage);
export { cSilentPage as SilentPage };
