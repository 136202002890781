import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { EditPermissions } from './EditPermissions';
import { PermissionsTable } from './PermissionsTable';

class WebPermissions extends React.Component {

    render() {
        return (
            <div>
                <Row>
                    <div className="white-box col-lg-12">
                        <h3 className="box-title">Web Permissions</h3>
                        <p>Here you can set the credentials of the server which can be used to view the logs via mTracker!
                            You can also view/remove people who have current access to the logs!</p>

                        <p>NOTE: Anyone with these credentials will have permission to view <b>all</b> the logs of the server! In-game permissions do not apply here!</p>
                        <EditPermissions serverID={this.props.serverID} server={this.props.server}/>
                        <PermissionsTable serverID={this.props.serverID}/>
                    </div>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { results } = state.logs;
    return {
        results
    };
}

const cWebPermissions = connect(mapStateToProps)(WebPermissions);
export { cWebPermissions as WebPermissions }; 