import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../_components';
import { ButtonGroup } from 'reactstrap';
import { manageActions } from '../../_actions'
import { steam } from '../../_helpers';
import ReactTable from 'react-table';

class PermissionsTable extends React.Component {
    constructor(props) {
        super(props);
        this.deleteUser = this.deleteUser.bind(this);
        this.setRowState = this.setRowState.bind(this);
    }

    componentDidMount(){
        if(!this.props.serverID){return;}
        this.props.dispatch(manageActions.getUsers(this.props.serverID));
    }

    setRowState(row){
        this.setState({user: row.original});
    }

    deleteUser(){
        const { user } = this.state;
        if(!user || !user.steamid){return;}
        var steamid64 = steam.to64(user.steamid);
        this.props.dispatch(manageActions.deleteUser(this.props.serverID,steamid64));
    }

    render() {
        const { users } = this.props;
        if(!users || !users.data){return (<div></div>)}
        const uData = Object.values(users.data || {});
        for(var i=0;i<uData.length;i++){
            if(!uData[i].steamid){continue;}
            uData[i].steamid = steam.to32(uData[i].steamid);
        }
        const pages = Math.ceil(uData.length / 5);
        return (
            <div>
                <ReactTable
                    columns = {[
                        {
                            Header: "Username",
                            accessor: "name",
                            Cell: row => (
                                <span><img class="p-l-5" src={row.original.avatar} alt="avatar" width={30} /> {row.value}</span>
                            )
                        },
                        {
                            Header: "SteamID",
                            accessor: "steamid",
                        },
                        {
                            Header: "Options",
                            accessor: "options",
                            filterable: false,
                            Cell: row => (
                                <button data-toggle="modal" data-target="#deleteUser" onClick={()=>this.setRowState(row)} className="btn btn-danger btn-block">Delete User</button>
                            )
                        }
                    ]}
                    data={uData}
                    pages={pages}
                    defaultFilterMethod={(filter,row)=>{
                        const id = filter.pivotId || filter.id;
                        return String(row[id]).toLowerCase().includes(filter.value.toLowerCase());
                    }}
                    filterable
                    defaultPageSize={5}
                    pageSize={5}
                    showPageSizeOptions={false}
                    loading={false}
                    noDataText="No users found"
                />
                <Modal name="deleteUser" title="Delete User" footer={(
                    <div className="modal-footer">
                        <ButtonGroup>
                            <button data-dismiss="modal" onClick={this.deleteUser} className="btn btn-danger">Delete User</button>
                            <button className="btn btn-default" data-dismiss="modal">Cancel</button>
                        </ButtonGroup>
                    </div>
                )}>
                    Are you sure you want to delete this user?
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state.manage;
    return {
        users
    };
}

const cPermissionsTable = connect(mapStateToProps)(PermissionsTable);
export { cPermissionsTable as PermissionsTable }; 