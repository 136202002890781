import 'core-js/stable';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import 'regenerator-runtime/runtime';
import { App } from './_app';
import { store } from './_helpers';
import userManager from './_helpers/userManager';

if (userManager) {
  render(
    <OidcProvider userManager={userManager} store={store}>
      <Provider store={store}>
        <App />
      </Provider>
    </OidcProvider>,
    document.getElementById('app')
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('app')
  );
}
