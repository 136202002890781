import React from 'react';
import Particles from 'react-particles-js';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { AppAlert } from '../../_components';
import { history } from '../../_helpers';
import userManager from '../../_helpers/userManager';
import ParticleSettings from './particles.json';

class CallbackPage extends React.Component {
	componentDidMount() {
		// window.addEventListener("message", this.handleFrameTasks);
		// this.ifr.onload = () => {
		// 	this.ifr.contentWindow.postMessage("getToken","https://login.m4dsolutions.com");
        // }
	}

    render() {
        return (
            <CallbackComponent 
                userManager={userManager}
                successCallback={()=>{history.push('/');}}
                errorCallback={(error)=>{console.error(error);history.push('/');}}>
                    
                <div id="wrapper" className="login-register">
                <Particles style={{"position": "absolute","top":0,"zIndex":-1000}} params={ParticleSettings}/>
                    <div className="login-box">
                        <div className="white-box text-center">
                            <h3 className="box-title m-lower m-b-20">Welcome to mTracker</h3>
                            <AppAlert />
                            <button className="btn btn-info btn-block"
                                disabled>Logging in...</button>
                        </div>
                    </div>
                </div>
            </CallbackComponent>
        )
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state;
    return {
        loggingIn
    };
}

const cCallbackPage = connect(mapStateToProps)(CallbackPage);
export { cCallbackPage as CallbackPage };
