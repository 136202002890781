import { authHeader, handleResponse } from '../_helpers';
import { config } from '../config';

export const logsService = {
    getLogs,
    getCategories,
    getSchema
};

function getLogs(id,options){
    options = options || {}
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    
    return fetch(`${config.api_url}/logs/${id}/?next=${encodeURIComponent(options.next || 0)
    }&prev=${encodeURIComponent(options.previous || 0)}&search=${encodeURIComponent(options.search || 0)
    }&category=${options.category || 0}`,requestOptions).then(handleResponse);
}

function getCategories(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    
    return fetch(`${config.api_url}/logs/categories/${id}`,requestOptions).then(handleResponse);
}

function getSchema(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    
    return fetch(`${config.api_url}/schema/client/${id}`,requestOptions).then(handleResponse);
}