import { SILENT_RENEW_ERROR, USER_EXPIRED, USER_FOUND, USER_SIGNED_OUT } from 'redux-oidc';
import { userConstants } from '../_constants';

//let user = JSON.parse(localStorage.getItem('m_user'));
const initialState = {};

export function auth(state = initialState, action) {
  switch (action.type) {
    case userConstants.AUTH_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.AUTH_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.USER_SUCCESS:
      return {
        ...state,
        user: {...state.user, ...action.user}
      }
    case userConstants.AUTH_FAILURE:
      return {};
    case userConstants.LOGOUT:
    case USER_SIGNED_OUT:
    case SILENT_RENEW_ERROR:
    case USER_EXPIRED:
      return {};
    case USER_FOUND:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.payload.profile,
        token: action.payload.access_token
      }
    
    default:
      return state
  }
}