import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { LogBrowser } from '../ServerPage';
import { AppAlert } from '../../_components';
class EmbedPage extends React.Component {
    render() {
        const { id, key } = this.props.match.params;
        return (
            <div id="container">
                <Col lg="12">
                    <Row>
                        <AppAlert />
                    </Row>
                    <Row>
                        <LogBrowser serverID={id} embed={key}/>
                    </Row>
                </Col>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.auth;
    return {
        loggingIn
    };
}

const cEmbedPage = connect(mapStateToProps)(EmbedPage);
export { cEmbedPage as EmbedPage }; 