import { userService } from "../_services";
import { history } from "./history";
import { store } from "./store";
import { userActions } from "../_actions";

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};


export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        console.log([response.status, data]);
        if(response.status == 401){
            if(data && data.message == "Steam account not linked!")
            {
                history.push('/steam');
                return;
            }
            // Logout
            store.dispatch(userActions.logout());
            history.push('/');
            return;
        }

        if (!response.ok) {
            const error = (data && data.message) || "An error occured whilst performing a request.";
            return Promise.reject(error);
        }
 
        return data;
    });
}