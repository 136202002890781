import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageWrapper } from '../../_components';
import { planActions } from '../../_actions';
import { Row, Col } from 'reactstrap';
import { AddServer } from './AddServer';
import { Widget } from './Widget';
import './HomePage.css';
import _ from 'lodash';

class HomePage extends React.Component {
    componentDidMount(){
        if(!this.props.plan.loaded){
            this.props.dispatch(planActions.getPlan());
        }
    }
    render() {
        const { plan, servers } = this.props;
        const serverAmount = _.size(servers);
        return (
            <PageWrapper title="Home">
                <Col md="12">
                    <Row>
                        <div className="white-box">
                            <Row className="row-in">
                                <Col md="4" sm="12" className="row-in-br">
                                    <Widget 
                                        icon="fa fa-server"
                                        desc="How many servers you are using out of your total."
                                        title="SERVER USAGE"
                                        counter={<div>{serverAmount}<h5 className="m-inline">/{(plan.servers === 0 && "\u221e") || plan.servers} servers</h5></div>}
                                        color="danger"
                                        percent={(serverAmount/(plan.servers || 1)) * 100}
                                    />
                                </Col>
                                <Col md="4" sm="12" className="row-in-br">
                                    <Widget 
                                        icon="fa fa-clock-o"
                                        desc="How long for each log to expire."
                                        title="LOG STORAGE TIME"
                                        counter={<div>{plan.logTime}<h5 className="m-inline">/730 days</h5></div>}
                                        color="info"
                                        percent={((plan.logTime)/730) * 100}
                                    />
                                </Col>
                                <Col md="4" sm="12" className="b-0">
                                    <div className="white-box text-center">
                                        <p>Need more resources?</p>
                                        <Link to="/upgrades" className="btn btn-block btn-success m-t-20">Upgrade</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="panel panel-info">
                            <div className="panel-heading">
                                Add a Server
                            </div>
                            <div className="panel-body">
                                <p>If your Server Admin has given you credentials to add their server then you can enter them below:</p>
                                <AddServer />
                            </div>
                        </div>
                    </Row>
                </Col>
			</PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { plan, manage } = state;
    const { data } = manage.servers;
    return {
        plan,
        servers: data
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage }; 