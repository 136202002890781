import { config } from '../config';

export const embedService = {
    getLogs,
    getCategories,
    getSchema
};

function getLogs(id,options,embed){
    options = options || {}
    const requestOptions = {
        method: 'GET',
        headers: {"token": embed}
    }
    
    return fetch(`${config.api_url}/embed/${id}/?next=${encodeURIComponent(options.next || 0)
    }&prev=${encodeURIComponent(options.previous || 0)}&search=${encodeURIComponent(options.search || 0)
    }&category=${options.category || 0}`,requestOptions).then(handleResponse);
}

function getCategories(id,embed){
    const requestOptions = {
        method: 'GET',
        headers: {"token": embed}
    }
    
    return fetch(`${config.api_url}/embed/categories/${id}`,requestOptions).then(handleResponse);
}

function getSchema(id,embed){
    const requestOptions = {
        method: 'GET',
        headers: {"token": embed}
    }
    
    return fetch(`${config.api_url}/embed/schema/${id}`,requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || "An error occured whilst performing a request.";
            return Promise.reject(error);
        }
 
        return data;
    });
}