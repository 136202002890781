import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../_components';
import { ButtonGroup } from 'reactstrap';
import ReactTable from "react-table";
import { manageActions } from '../../_actions';
import { EmbedServer } from './EmbedServer';
import "react-table/react-table.css";
import "./KeyServers.css";
class KeyServers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            loading: true,
            ignoreFetch: true,
            pageSize: 5,
            server: {name:"",_id:""}
        }

        this.fetchData = this.fetchData.bind(this);
        this.setRowState= this.setRowState.bind(this);
        this.deleteServer = this.deleteServer.bind(this);
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData(state, instance){
        const { ignoreFetch } = this.state;
        if(ignoreFetch && Object.keys(this.props.data).length !== 0){
            this.setState({ignoreFetch:false});
            return;
        }
        this.props.dispatch(manageActions.getServers());
    }

    setRowState(row){
        this.setState({server:row.original});
    }

    deleteServer(){
        const { server } = this.state;
        if(server._id === ""){ return;}
        this.props.dispatch(manageActions.deleteServer(server._id));
    }

    render() {
        const { data, loading, pages } = this.props;
        const { server } = this.state;
		const sData = Object.values(data || {});
        const { pageSize } = this.state;
        return (
            <div>
                <ReactTable
                    columns = {[
                        {
                            Header: "Server Name",
                            accessor: "name"
                        },
                        {
                            Header: "API Key",
                            accessor: "api_key"
                        },
                        {
                            Header: "Options",
                            accessor: "options",
                            Cell: row => (
                                <ButtonGroup className="btn-group-justified">
                                    <a href="#embedserver" data-toggle="modal" data-target="#embedDialog" onClick={()=>this.setRowState(row)} className="btn btn-primary">Embed</a>
                                    <a href="#deleteserverlogs" data-toggle="modal" data-target="#logDialog" onClick={()=>this.setRowState(row)} className="btn btn-warning">Delete Logs</a>
                                    <a href="#deleteserver" data-toggle="modal" data-target="#deleteDialog" onClick={()=>this.setRowState(row)} className="btn btn-danger">Delete Server</a>
                                </ButtonGroup>
                            )
                        }
                    ]}
                    data={sData}
                    pages={pages}
                    filterable={false}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    showPageSizeOptions={false}
                    loading={loading}
                    
                />
                
                <Modal name="logDialog" title="Delete Logs" footer={(
                    <div className="modal-footer">
                        <ButtonGroup>
                            <button className="btn btn-danger">Delete Logs</button>
                            <button className="btn btn-default" data-dismiss="modal">Cancel</button>
                        </ButtonGroup>
                    </div>
                )}>
                    Are you sure you want to delete all logs? This action is irreversible!
                </Modal>
                <Modal name="embedDialog" lg title="Embed">
                    <EmbedServer serverID={server._id} />
                </Modal>
                <Modal name="deleteDialog" title="Delete Server" footer={(
                    <div className="modal-footer">
                        <ButtonGroup>
                            <button className="btn btn-danger" onClick={this.deleteServer} data-dismiss="modal">Delete Server</button>
                            <button className="btn btn-default" data-dismiss="modal">Cancel</button>
                        </ButtonGroup>
                    </div>
                )}>
                    Are you sure you want to delete this server? All logs will be deleted! This action is irreversible!
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, loading, pages } = state.manage.servers;
    return {
        data,
        loading,
        pages
    };
}

const cKeyServers = connect(mapStateToProps)(KeyServers);
export { cKeyServers as KeyServers }; 