import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import DocumentTitle from 'react-document-title';

import { history, gTracker } from '../_helpers';
import { alertActions, userActions } from '../_actions';
import { PrivateRoute, SteamRoute } from '../_components';

import { HomePage } from '../_pages/HomePage';
import { LoginPage, CallbackPage, SilentPage } from '../_pages/LoginPage';
import { SteamPage } from '../_pages/SteamPage';
import { KeyPage } from '../_pages/KeyPage';
import { ServerPage } from '../_pages/ServerPage';
import { EmbedPage } from '../_pages/EmbedPage';
import { UpgradesPage } from '../_pages/UpgradesPage';
import { LoadingPage } from '../_pages/LoginPage/LoadingPage';

class App extends React.Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    componentDidMount(){
        //this.props.dispatch(userActions.validate());
    }

    render() {
        return (
            <DocumentTitle title="mTracker">
                <div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                        />
                    <ToastContainer />
                    <Router history={history}>
                        <Switch>
                            <Route path="/embed/:id/:key" component={gTracker(EmbedPage)} />
                            <Route path="/login/callback" component={CallbackPage} />
                            <Route path="/login/silent" component={SilentPage} />
                            <Route path="/login" component={gTracker(LoginPage)} />
                            <Route path="/loading" component={LoadingPage} />
                            <SteamRoute path="/steam" component={gTracker(SteamPage)} />

                            <PrivateRoute exact path="/" component={gTracker(HomePage)} />
                            <PrivateRoute path="/key-management" component={gTracker(KeyPage)} />
                            <PrivateRoute path="/upgrades" component={gTracker(UpgradesPage)} />

                            <PrivateRoute path="/server/:id" component={gTracker(ServerPage)} />

                            <Redirect from='*' to='/' />
                        </Switch>
                    </Router>
                </div>
            </DocumentTitle>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
