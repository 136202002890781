import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../_helpers';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const {auth} = store.getState();
        if(auth.loggedIn && auth.user && auth.user.steamid){
            return <Component {...props} />
        }else if(auth.user){
            return <Redirect to={{ pathname: '/loading', state: { from: props.location } }} />
        }else{
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
    }} />
)