import { store } from "./store";

export function authHeader() {
    let token = store.getState().auth.token;
    
    if (token) {
        return { 'Authorization': 'Bearer ' + token, Accept: "application/json" };
    } else {
        return {};
    }
}