import React from 'react';
import { connect } from 'react-redux';
import { AppAlert } from '../../_components';
import { authConstants } from '../../_constants';
import { alertActions, userActions } from '../../_actions';
import Particles from 'react-particles-js';
import ParticleSettings from './particles.json';
import userManager from '../../_helpers/userManager';
import { history } from '../../_helpers';

class LoadingPage extends React.Component {
	componentDidMount() {
		const {user, dispatch} = this.props;
		if(user && user.steamid){
			history.push("/");
		}else{
			dispatch(userActions.getUser());
		}
    }
    
	componentDidUpdate() {
		this.checkSteamID();
	}

	checkSteamID = () => {
		const {user} = this.props;
		if(user && user.steamid){
			history.push("/");
		}
	}
    render() {
        return (
			<div id="wrapper" className="login-register">
				<Particles style={{"position": "absolute","top":0,"zIndex":-1000}} params={ParticleSettings}/>
				<div className="login-box">
					<div className="white-box text-center">
						<h3 className="box-title m-lower m-0">Loading...</h3>
					</div>
				</div>
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

const cLoadingPage = connect(mapStateToProps)(LoadingPage);
export { cLoadingPage as LoadingPage }; 