import { logsConstants } from '../_constants';

const initialState = {
    results: [],
    hasNext: false,
    loading: false,
    categories: [],
    schema: [{
        name: "Logs",
        color: "#ff6400",
        key: "logs",
        hooks: [
            {
                name: "All Logs",
                key: "all"
            }
        ]
    }]
};

export function logs(state = initialState, action) {
    switch (action.type) {
        case logsConstants.GET_LOGS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case logsConstants.GET_LOGS_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false
            }
        case logsConstants.GET_LOGS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case logsConstants.GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case logsConstants.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.data,
                loading:false,
            }
        case logsConstants.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case logsConstants.GET_SCHEMA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case logsConstants.GET_SCHEMA_SUCCESS:
            return {
                ...state,
                schema: [
                    ...initialState.schema,
                    ...action.data,
                ],
                loading: false,
            }
        case logsConstants.GET_SCHEMA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}