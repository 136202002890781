export const config = {
	steam_url: 'https://steamcommunity.com/openid/login',
	app_url: 'https://tracker.m4dsolutions.com',
	//app_url: 'http://localhost:3000',
	api_url: 'https://api.m4dsolutions.com/mtracker',
	//api_url: 'http://localhost:3001',

	login_url: "https://login.m4dsolutions.com",
	clientID: "90bff299-c827-4e1b-a2b1-bfa164c4b0a7",
	recaptcha_site_key: "6LddtWUUAAAAABpI20rR7VvGnu5gOrxLMpOXeXGA",
}