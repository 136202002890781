export class FakeStateStore {
  constructor({ prefix = 'oidc.', store = {} } = {}) {
    this._store = store;
    this._prefix = prefix;
  }

  set(key, value) {
    key = this._prefix + key;

    this._store[key] = value;

    return Promise.resolve();
  }

  get(key) {
    key = this._prefix + key;

    let item = this._store[key];

    return Promise.resolve(item);
  }

  remove(key) {
    key = this._prefix + key;

    let item = this._store.getItem(key);
    this._store.removeItem(key);

    return Promise.resolve(item);
  }

  getAllKeys() {
    var keys = [];

    for (let index = 0; index < this._store.length; index++) {
      let key = this._store.key(index);

      if (key.indexOf(this._prefix) === 0) {
        keys.push(key.substr(this._prefix.length));
      }
    }

    return Promise.resolve(keys);
  }
}
