export const logsConstants = {
    GET_LOGS_REQUEST: 'LOGS_GET_LOGS_REQUEST',
    GET_LOGS_SUCCESS: 'LOGS_GET_LOGS_SUCCESS',
    GET_LOGS_FAILURE: 'LOGS_GET_LOGS_FAILURE',

    GET_CATEGORIES_REQUEST: 'LOGS_GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'LOGS_GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'LOGS_GET_CATEGORIES_FAILURE',

    GET_SCHEMA_REQUEST: 'LOGS_GET_SCHEMA_REQUEST',
    GET_SCHEMA_SUCCESS: 'LOGS_GET_SCHEMA_SUCCESS',
    GET_SCHEMA_FAILURE: 'LOGS_GET_SCHEMA_FAILURE',
};