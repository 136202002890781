import { manageConstants } from '../_constants';
import { manageService } from '../_services';
import { alertActions } from './';

export const manageActions = {
    getServers,
    addServer,
    deleteServer,
    requestAccess,
    editPermissions,
    getUsers,
    deleteUser,
    resetEmbed
};

function getServers(options){
	return dispatch => {
		dispatch(request());
        
        manageService.getServers(options)
            .then(
                sData => {
                    var newServers = {};
                    for(var i=0;i<sData.servers.length;i++){
                        var d = sData.servers[i];
                        if(!d._id){continue;}
                        newServers[d._id] = d;
                    }
                    sData.servers = newServers;
                    dispatch(success(sData));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.GET_SERVERS_REQUEST } }
    function success(sData) { return { type: manageConstants.GET_SERVERS_SUCCESS, sData } }
    function failure(error) { return { type: manageConstants.GET_SERVERS_FAILURE, error } }
}

function getUsers(id){
	return dispatch => {
		dispatch(request());
        
        manageService.getUsers(id)
            .then(
                users => {
                    var newUsers = {};
                    for(var i=0;i<users.length;i++){
                        var u = users[i];
                        newUsers[u.steamid] = u;
                    }
                    dispatch(success(newUsers));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.USERS_REQUEST } }
    function success(users) { return { type: manageConstants.USERS_SUCCESS, users } }
    function failure(error) { return { type: manageConstants.USERS_FAILURE, error } }
}

function addServer(name,recaptcha){
	return dispatch => {
		dispatch(request());
        
        manageService.addServer(name,recaptcha)
            .then(
                server => {
                    dispatch(success(server));
                    alertActions.toast("Added " + server.name + " successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.ADD_REQUEST } }
    function success(server) { return { type: manageConstants.ADD_SUCCESS, server } }
    function failure(error) { return { type: manageConstants.ADD_FAILURE, error } }
}

function deleteServer(_id){
	return dispatch => {
		dispatch(request());
        
        manageService.deleteServer(_id)
            .then(
                id => {
                    dispatch(success(id));
                    alertActions.toast("Deleted server successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.DELETE_REQUEST } }
    function success(id) { return { type: manageConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: manageConstants.DELETE_FAILURE, error } }
}

function deleteUser(serverid,steamid){
	return dispatch => {
		dispatch(request());
        
        manageService.deleteUser(serverid,steamid)
            .then(
                id => {
                    dispatch(success(id));
                    alertActions.toast("Deleted user successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.DELETE_USER_REQUEST } }
    function success(id) { return { type: manageConstants.DELETE_USER_SUCCESS, id } }
    function failure(error) { return { type: manageConstants.DELETE_USER_FAILURE, error } }
}
function requestAccess(id,password,recaptcha){
	return dispatch => {
		dispatch(request());
        
        manageService.requestAccess(id,password,recaptcha)
            .then(
                server => {
                    dispatch(success(server));
                    alertActions.toast("Added " + server.name + " successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.ADD_REQUEST } }
    function success(server) { return { type: manageConstants.ADD_SUCCESS, server } }
    function failure(error) { return { type: manageConstants.ADD_FAILURE, error } }
}

function editPermissions(id,password,recaptcha){
	return dispatch => {
		dispatch(request());
        
        manageService.editPermissions(id,password,recaptcha)
            .then(
                server => {
                    dispatch(success(server));
                    alertActions.toast("Updated " + server.name + " successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.ADD_REQUEST } }
    function success(server) { return { type: manageConstants.ADD_SUCCESS, server } }
    function failure(error) { return { type: manageConstants.ADD_FAILURE, error } }
}
function resetEmbed(id){
	return dispatch => {
		dispatch(request());
        
        manageService.resetEmbed(id)
            .then(
                server => {
                    dispatch(success(server));
                    alertActions.toast("Reset " + server.name + "'s embed code successfully!");
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: manageConstants.ADD_REQUEST } }
    function success(server) { return { type: manageConstants.ADD_SUCCESS, server } }
    function failure(error) { return { type: manageConstants.ADD_FAILURE, error } }
}