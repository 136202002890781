import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import { config } from '../config';
import userManager from '../_helpers/userManager';

export const userActions = {
    auth,
    logout,
    validate,
    getUser
};

function auth(token){
	return dispatch => {
		dispatch(request());
		
		userService.auth(token)
			.then(
				user => {
					dispatch(success(user));
					history.push('/');
				},
				error => {
					dispatch(failure(error));
					dispatch(alertActions.error(error.toString()));
				}
			);
		
	}
	
    function request() { return { type: userConstants.AUTH_REQUEST } }
    function success(user) { return { type: userConstants.AUTH_SUCCESS, user } }
    function failure(error) { return { type: userConstants.AUTH_FAILURE, error } }
}

function getUser(){
	return dispatch => {
		dispatch(request());
		
		userService.getUser()
			.then(
				user => {
					dispatch(success(user));
				},
				error => {
					dispatch(failure(error));
					dispatch(alertActions.error(error.toString()));
				}
			);
		
	}
	
    function request() { return { type: userConstants.USER_REQUEST } }
    function success(user) { return { type: userConstants.USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }
}


function validate(){
    // Get token
    var token = localStorage.getItem("m_auth");
    return dispatch => {
        dispatch(request());
        if(!token){
            if(localStorage.getItem("m_user")){
                userService.logout();
                history.push('/');
            }
            dispatch(failure("No token found!"));
            return;
        }
        userService.validate(token)
            .then(
                success_ => {
                    if(success_ === 0){
                        userService.logout();
                        history.push('/');
                        dispatch(failure("Token invalid!"));
                        return;
                    }
                    dispatch(success(token));
                },
                error => {
                    userService.logout();
                    history.push('/');
                    dispatch(failure("Failed to validate!"));
                    return;
                }
            );
    }
    function request() { return { type: userConstants.VALIDATE_REQUEST } }
    function success(jwt) { return { type: userConstants.VALIDATE_SUCCESS, jwt } }
    function failure(error) { return { type: userConstants.VALIDATE_FAILURE, error } }
}

function logout() {
    userManager.signoutRedirect();
    return { type: userConstants.LOGOUT }
}
/* function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => { 
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
} */