import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import {config} from '../../config';
import { manageActions } from '../../_actions';
import './EmbedServer.css';

class EmbedServer extends React.Component {
    constructor(props){
        super(props);

        this.resetCode = this.resetCode.bind(this);
    }

    resetCode(){
        const { servers } = this.props;
        if(!servers[this.props.serverID]){return;}
        this.props.dispatch(manageActions.resetEmbed(this.props.serverID));
    }

	render() {
        const { servers } = this.props;
        if(!servers[this.props.serverID]){return <div></div>;}
        var server = servers[this.props.serverID];
		return (
            <div className="vtabs customvtab">
                <ul className="nav tabs-vertical">
                    <li className="tab active"><a data-toggle="tab" href="#embed-1">What is this?</a></li>
                    <li className="tab"><a data-toggle="tab" href="#embed-2">Simple Embed</a></li>
                    <li className="tab"><a data-toggle="tab" href="#embed-3">Secure Embed</a></li>
                </ul>
                <div className="tab-content p-t-0">
                    <div id="embed-1" className="tab-pane active text-white">
                        <h3>What is this?</h3>
                        <p>mTracker Embed allows you to show your server's logs on your own website!</p>
                            
                        <p>This allows your users to easily view logs without being in-game!</p>
                    </div>
                    <div id="embed-2" className="tab-pane text-white">
                        <h3>Simple Embed</h3>
                        <div className="alert alert-danger">This method is less secure due to anyone with the link is able to view logs
                        without being authorized!</div>
                        <p>Simple Embed allows you to add logs to your site very easily, without much knowledge of coding. However, comes with
                            the con of not being able to control who has access to it.
                        </p>
                        <hr/>
                        <ol>
                            <li>
                                <p>Copy the HTML code below:</p>
                                <pre className="embed-code">
                                &#x3C;iframe src=&#x22;{config.app_url}/embed/{server._id}/{server.embed}&#x22; width=&#x22;1500px&#x22; height=&#x22;800px&#x22;&#x3E;&#x3C;/iframe&#x3E;
                                </pre>
                            </li>
                            <li>Paste the code into your website's HTML file/editor</li>
                            <li>All done! Refresh the page and mTracker logs should be displayed on your site</li>
                        </ol>
                        <hr />
                        <p>If you feel that the code has been leaked or would like to revoke access to the current embed script, you can reset it below:</p>
                        <Button color="danger" block onClick={this.resetCode}>Reset Embed Code</Button>
                    </div>
                    <div id="embed-3" className="tab-pane text-white">
                        <h3>Secure Embed</h3>
                        This method is more secure, however requires a little extra installation to use! The following steps will be
                        for <span className="label label-info">PHP</span>. However, if you need help setting up with a different language send a support ticket.
                        <h4>How is this more secure?</h4>
                        <p>This is more secure as your website requests mTracker to generate a key for each of your users, which grants them 30 minutes of access.
                            Therefore, they will lose access if they cannot access the page.
                        </p>
                        <hr/>
                        <ol>
                            <li>
                                <p>Place the following code at the top of the page you want the logs to show on:</p>
                                <pre className="embed-code">
                                    &#x3C;?php<br/>
                                        $mt_url = &#x22;{config.api_url}/embed/{server._id}/access/{server.embed}&#x22;;<br/>
                                        $mt_ch = curl_init();<br/>
                                        curl_setopt($mt_ch, CURLOPT_URL, $mt_url);<br/>
                                        curl_setopt($mt_ch, CURLOPT_RETURNTRANSFER, 1);<br/>
                                        $mt_clientSecureKey = curl_exec($mt_ch);<br/>
                                    ?&#x3E;
                                </pre>
                            </li>
                            <li>
                                <p>Then, place the following code where you want mTracker to display the logs:</p>
                                <pre className="embed-code">
                                &#x3C;iframe src=&#x22;{config.app_url}/embed/{server._id}/&#x3C;?php echo $mt_clientSecureKey; ?&#x3E;?&#x26;s=1&#x22; width=&#x22;1500px&#x22; height=&#x22;800px&#x22;&#x3E;&#x3C;/iframe&#x3E;
                                </pre>
                            </li>
                            <li>All done! Refresh the page and mTracker logs should be displayed on your site</li>
                        </ol>
                        <hr />
                        <p>If you feel that the code has been leaked or would like to revoke access to the current embed script, you can reset it below:</p>
                        <Button color="danger" block onClick={this.resetCode}>Reset Embed Code</Button>
                    </div>
                </div>
            </div>
		)
	}
}
function mapStateToProps(state) {
    const { data } = state.manage.servers;
    return {
        servers: data,
    };
}

const cEmbedServer = connect(mapStateToProps)(EmbedServer);
export { cEmbedServer as EmbedServer }; 