import React from 'react';
import { connect } from 'react-redux';
import { PageWrapper } from '../../_components';
import { Row, Col } from 'reactstrap';
import { KeyServers } from './KeyServers';
import { KeyAddServer } from './KeyAddServer';

class KeyPage extends React.Component {
    constructor(props) {
        super(props);
        this.optionsDialog = React.createRef();
    }

    render() {
        return (
            <div>
                <PageWrapper title="Key Management">
                    <Row>
                        <Col md="12">
                            <div className="white-box col-lg-12">
                                <h3 className="box-title m-lower">Using mTracker</h3>
                                In order for your server to interact with mTracker, you need to create an API Key here. Follow this guide <a href="https://docs.m4dsolutions.com" target="__blank">here</a> if you need help.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="white-box">
                                <h3 className="box-title">Registered Servers</h3>
                                <KeyServers />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <div className="white-box">
                                <h3 className="box-title">Add a Server</h3>
                                <KeyAddServer />
                            </div>
                        </Col>
                    </Row>
                </PageWrapper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.auth;
    return {
        loggingIn
    };
}

const cKeyPage = connect(mapStateToProps)(KeyPage);
export { cKeyPage as KeyPage }; 