import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { manage } from './manage.reducer';
import { logs } from './logs.reducer';
import { plan } from './plan.reducer';

const rootReducer = combineReducers({
	alert,
	auth,
	manage,
	logs,
	plan
});

export default rootReducer;