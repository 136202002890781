import React from 'react';
import { Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {config} from '../../config';
import Recaptcha from "react-recaptcha";
import { manageActions } from '../../_actions';
import './EditPermissions.css';

class EditPermissions extends React.Component {
	render() {
		const { editPermissions, loading, server } = this.props;
		
		return (
			<div>
			<Formik initialValues = {
				{
                    serverid: '',
					password: server.password || "",
					recaptcha: '',
				}
			}
			validate = {
				values => {
					let errors = {};
					if (!values.password) {
						errors.password = 'Password cannot be empty!';
					}
		
					if(!values.recaptcha){
						errors.recaptcha = "reCaptcha needs completing!"
					}
					
					return errors;
				}
			}
			onSubmit = {
				(
					values, {
						setSubmitting,
						setErrors /* setValues and other goodies */
					}
				) => {
					var sub = {
						...values,
						serverid: server._id
					}
					editPermissions(sub);
				}
			}
			render = {
				({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue
				}) => ( 
					<Row>
						<form onSubmit={handleSubmit}>
							<Col lg="12">
								<div className="form-group col-lg-12">
									<label htmlFor="serverid" className="control-label">Server ID:</label>
									<input type="text" id="serverid" name="serverid" className="form-control" placeholder="Enter Server ID" 
										onChange={handleChange} onBlur={handleBlur} value={this.props.serverID} readOnly/>
									{touched.serverid && errors.serverid && <div>{errors.serverid}</div>}
								</div>
								<div className="form-group col-lg-12">
									<label htmlFor="password" className="control-label">Password:</label>
									<input type="text" id="password" name="password" className="form-control" placeholder="Enter Server Password" 
										onChange={handleChange} onBlur={handleBlur} value={values.password}/>
									{touched.password && errors.password && <div>{errors.password}</div>}
								</div>
								<div className="form-group inline-captcha col-lg-12">
									<label htmlFor="recaptcha" className="control-label col-lg-12">Captcha:</label>
									<Recaptcha
										sitekey={config.recaptcha_site_key}
										theme="dark"
										render="explicit"
										verifyCallback={(response) => {setFieldValue("recaptcha",response);}}
										onloadCallback={() => {}}
									/>
									{touched.recaptcha && errors.recaptcha && <div>{errors.recaptcha}</div>}
								</div>
                                <div className="form-group col-lg-12">
                                    <button className="btn btn-info btn-block" type="submit" disabled={loading}>
                                        Update Password
                                    </button>
                                </div>
							</Col>
						</form>
					</Row>
				)
			}
			/> 
			</div>
		)
	}
}
function mapStateToProps(state) {
    const { loading } = state.manage.servers;
    return {
        loading,
    };
}

function mapDispatchToProp(dispatch){
	return {
		editPermissions: (values) => {
			dispatch(manageActions.editPermissions(values.serverid,values.password,values.recaptcha));
		}
	}
}

const cEditPermissions = connect(mapStateToProps,mapDispatchToProp)(EditPermissions);
export { cEditPermissions as EditPermissions }; 