export const userConstants = {
    AUTH_REQUEST: 'USER_AUTH_REQUEST',
    AUTH_SUCCESS: 'USER_AUTH_SUCCESS',
    AUTH_FAILURE: 'USER_AUTH_FAILURE',

    USER_REQUEST: 'USER_USER_REQUEST',
    USER_SUCCESS: 'USER_USER_SUCCESS',
    USER_FAILURE: 'USER_USER_FAILURE',

    VALIDATE_REQUEST: 'USER_VALIDATE_REQUEST',
    VALIDATE_SUCCESS: 'USER_VALIDATE_SUCCESS',
    VALIDATE_FAILURE: 'USER_VALIDATE_FAILURE',
	
	LOGOUT: 'USER_LOGOUT',
};
