import React from 'react';
import classNames from 'classnames';
import './PricingTable.css';

class PricingTable extends React.Component {
    render() {
        const { title, price, features, btnText, btnLink, featured, last, noLeft, current } = this.props;
        const pFeatures = features.map((obj,idx)=>{
            return <div key={"p-" + idx} className="price-row"><i className={obj.icon}></i> {obj.feature}</div>
        });
        const pricingClass = classNames({
            "pricing-box": true,
            "featured-plan": featured
        });
        return (
            <div className={pricingClass}>
                <div className={classNames({"pricing-body":true,"b-l": !noLeft, "b-r": last})}>
                    <div className="pricing-header">
                        {(() => {
                            return featured && (
                                <h4 className="price-lable text-white bg-success">Popular</h4>
                            )
                        })()}
                        <h4 className="text-center">{title}</h4>
                        <h2 className="text-center"><span className="price-sign">$</span>{price}</h2>
                        <p className="uppercase">per month</p>
                    </div>
                    <div className="price-table-content">
                        {pFeatures}
                        {(()=>{
                            if(btnText && !current){
                                return (
                                    <div className="price-row">
                                        <a href={btnLink} target="__blank" className="btn btn-success waves-effect waves-light m-t-20">{btnText}</a>
                                    </div>
                                )
                            }
                        })()}
                        {(()=>{
                            if(current){
                                return (
                                    <div className="price-row">
                                        <span className="btn btn-outline btn-danger m-t-20">Current Plan</span>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export { PricingTable };