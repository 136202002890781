import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';

GoogleAnalytics.initialize('UA-123740304-1');

export const gTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		GoogleAnalytics.set({
			page,
			...options,
		});
		GoogleAnalytics.pageview(page);
	};

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		componentDidUpdate(nextProps) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};