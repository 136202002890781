import React from 'react';
import { connect } from 'react-redux';

class AppAlert extends React.Component {
	
	render(){
        const { alert } = this.props;
		return (
			<div>
				{alert.message &&
					<div className={`alert ${alert.type}`}>{alert.message}</div>
				}
			</div>
		)
	}
}


function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const cAppAlert = connect(mapStateToProps)(AppAlert);
export { cAppAlert as AppAlert }; 