import { planConstants } from '../_constants';

const initialState = {
    name: "free",
    servers: 3,
    logTime: 7
};

export function plan(state = initialState, action) {
    switch (action.type) {
        case planConstants.GET_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case planConstants.GET_PLAN_SUCCESS:
            return {
                ...action.data,
                loading: false,
                loaded: true
            }
        case planConstants.GET_PLAN_FAILURE:
            return initialState;
        default:
            return state;
    }
}