import { manageConstants } from '../_constants';

const initialState = {
    servers: {data:{},loading:true},
    users: {users:{},loading: true}
};

export function manage(state = initialState, action) {
    switch (action.type) {
        case manageConstants.GET_SERVERS_REQUEST:
            return {
                ...state,
                servers:{
                    data:{},loading:true
                }
            };
        case manageConstants.GET_SERVERS_SUCCESS:
            return {
                ...state,
                servers:{
                    data: action.sData.servers,
                    pages: action.sData.pages || null,
                    loading:false
                }
            }
        case manageConstants.GET_SERVERS_FAILURE:
            return initialState;
        case manageConstants.ADD_REQUEST:
            return {
                ...state,
                servers: {
                    ...state.servers,
                    loading: true
                }
            }
        case manageConstants.ADD_SUCCESS:
            return {
                ...state,
                servers: {
                    data: {...state.servers.data,[action.server._id]:action.server},
                    loading:false
                }
            }
        case manageConstants.ADD_FAILURE:
            return {
                ...state,
                servers: {
                    ...state.servers,
                    loading: false
                }
            }
        case manageConstants.DELETE_REQUEST:
            return {
                ...state,
                servers: {
                    ...state.servers,
                    loading: true
                }
            }
        case manageConstants.DELETE_SUCCESS:
            const cState = {
                ...state,
                servers: {
                    data: {...state.servers.data},
                    loading:false
                }
            }
            delete cState.servers.data[action.id.id];
            return cState;
        case manageConstants.DELETE_FAILURE:
            return {
                ...state,
                servers: {
                    ...state.servers,
                    loading: false
                }
            }
        case manageConstants.USERS_REQUEST:
            return {
                ...state,
                users:{
                    data:{},loading:true
                }
            };
        case manageConstants.USERS_SUCCESS:
            return {
                ...state,
                users:{
                    data: action.users,
                    loading:false
                }
            }
        case manageConstants.USERS_FAILURE:
            return initialState;
        case manageConstants.DELETE_USER_REQUEST:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: true
                }
            }
        case manageConstants.DELETE_USER_SUCCESS:
            const uState = {
                ...state,
                users: {
                    data: {...state.users.data},
                    loading:false
                }
            }
            delete uState.users.data[action.id.id];
            return uState;
        case manageConstants.DELETE_USER_FAILURE:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: false
                }
            }
        default:
            return state
    }
}