import React from 'react';
import { connect } from 'react-redux';
import { steam, history } from '../../_helpers';
import { config } from '../../config';
import { userActions } from '../../_actions';

class SteamPage extends React.Component {
	componentDidMount() {
		const {user, dispatch} = this.props;
		if(user && user.steamid){
			history.push("/");
		}else{
			dispatch(userActions.getUser());
		}
	}

	componentDidUpdate() {
		this.checkSteamID();
	}

	checkSteamID = () => {
		const {user} = this.props;
		if(user && user.steamid){
			history.push("/");
		}
	}

    render() {
        return (
			<div id="wrapper" className="login-register">
				<div className="login-box">
					<div className="white-box text-center">
						<h3 className="box-title m-lower m-b-20">Please link your Steam Account to use mTracker</h3>
						<a className="btn btn-primary" href={`${config.login_url}/account/link/steam?client=${config.clientID}`}>Link Steam Account</a>
					</div>
				</div>
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

const cSteamPage = connect(mapStateToProps)(SteamPage);
export { cSteamPage as SteamPage }; 