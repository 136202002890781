import React from 'react';

class Widget extends React.Component {
    render() {
        const { title, icon, counter, percent, color, desc } = this.props;
        return (
            <div className="col-in row">
                <div className="col-md-6 col-sm-6 col-xs-6">
                    <i className={icon} title={desc}></i>
                    <h5 className="text-muted vb">{title}</h5> 
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                    <h3 className={"counter text-right m-t-15 text-" + color}>{counter}</h3> 
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="progress">
                        <div className={"progress-bar progress-bar-" + color}
                            role="progressbar" 
                            aria-valuenow={percent} 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            style={{width: percent + "%"}}> 
                            <span className="sr-only">{percent}% Complete</span> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Widget }