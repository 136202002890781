import React from 'react';
import { connect } from 'react-redux';
import { PageWrapper } from '../../_components';
import { Row, Col } from 'reactstrap';
import { PricingTable } from './PricingTable';
import { planActions } from '../../_actions';

class UpgradesPage extends React.Component {
    componentDidMount(){
        if(!this.props.loaded){
            this.props.dispatch(planActions.getPlan());
        }
    }
    render() {
        const { name } = this.props;
        return (
            <PageWrapper title={"Upgrades"}>
                <Col md="12">
                    <Row>
                        <div className="white-box text-white">
                            mTracker can be fully used with every purchase of mLogs! However, for those who need more from mTracker, can upgrade to increase its functionality.
                        </div>
                    </Row>
                    <Row>
                        <div className="white-box">
                            <div className="row pricing-plan">
                                <Col md="3" xs="12" sm="6" className="no-padding">
                                    <PricingTable 
                                        title="Free"
                                        price="0"
                                        features={[
                                            {icon:"fa fa-archive",feature:"1 Week Log Storage"},
                                            {icon:"fa fa-server",feature:"3 Servers"},
                                        ]}
                                        current={name==="free"}
                                    />
                                </Col>
                                <Col md="3" xs="12" sm="6" className="no-padding">
                                    <PricingTable 
                                        title="Basic"
                                        price="2.50"
                                        features={[
                                            {icon:"fa fa-archive",feature:"3 Month Log Storage"},
                                            {icon:"fa fa-server",feature:"5 Servers"},
                                        ]}
                                        featured
                                        btnText="Upgrade"
                                        btnLink="https://account.m4dsolutions.com/cart.php?a=add&pid=22"
                                        current={name==="basic"}
                                    />
                                </Col>
                                <Col md="3" xs="12" sm="6" className="no-padding">
                                    <PricingTable 
                                        title="Pro"
                                        price="5"
                                        features={[
                                            {icon:"fa fa-archive",feature:"6 Month Log Storage"},
                                            {icon:"fa fa-server",feature:"10 Servers"},
                                        ]}
                                        btnText="Upgrade"
                                        btnLink="https://account.m4dsolutions.com/cart.php?a=add&pid=23"
                                        noLeft
                                        current={name==="pro"}
                                    />
                                </Col>
                                <Col md="3" xs="12" sm="6" className="no-padding">
                                    <PricingTable 
                                        title="Extreme"
                                        price="7.50"
                                        features={[
                                            {icon:"fa fa-archive",feature:"24 Month Log Storage"},
                                            {icon:"fa fa-server",feature:"Unlimited Servers"},
                                        ]}
                                        btnText="Upgrade"
                                        btnLink="https://account.m4dsolutions.com/cart.php?a=add&pid=24"
                                        last
                                        current={name==="extreme"}
                                    />
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Col>
			</PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { name } = state.plan;
    return {
        name
    };
}

const cUpgradesPage = connect(mapStateToProps)(UpgradesPage);
export { cUpgradesPage as UpgradesPage }; 