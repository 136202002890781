import { authHeader, handleResponse } from '../_helpers';
import { config } from '../config';

export const manageService = {
    getServers,
    addServer,
    deleteServer,
    requestAccess,
    editPermissions,
    getUsers,
    deleteUser,
    resetEmbed
};

function getServers(options){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.api_url}/servers`,requestOptions).then(handleResponse);
}

function addServer(name,captcha){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({name:name,'g-recaptcha-response': captcha})
    }

    return fetch(`${config.api_url}/servers/add`,requestOptions).then(handleResponse);
}

function deleteServer(id){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(id)}`,requestOptions).then(handleResponse);
}

function deleteUser(serverid,id){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(serverid)}/users/${encodeURIComponent(id)}/delete`,requestOptions).then(handleResponse);
}

function getUsers(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(id)}/users`,requestOptions).then(handleResponse);
}

function requestAccess(id,password,recaptcha){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({password,'g-recaptcha-response': recaptcha})
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(id)}/request`,requestOptions).then(handleResponse);
}

function editPermissions(id,password,recaptcha){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({password,'g-recaptcha-response': recaptcha})
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(id)}/edit`,requestOptions).then(handleResponse);
}

function resetEmbed(id){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }

    return fetch(`${config.api_url}/servers/${encodeURIComponent(id)}/embed`,requestOptions).then(handleResponse);
}