import { logsConstants } from '../_constants';
import { embedService } from '../_services';
import { alertActions } from './';

export const embedActions = {
    getLogs,
    getCategories,
    getSchema,
    searchLogs
};

function getLogs(id,options,embed){
	return dispatch => {
		dispatch(request());
        
        embedService.getLogs(id,options,embed)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_LOGS_REQUEST } }
    function success(data) { return { type: logsConstants.GET_LOGS_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_LOGS_FAILURE, error } }
}

function getCategories(id,embed){
	return dispatch => {
		dispatch(request());
        
        embedService.getCategories(id,embed)
            .then(
                data => {
                    var mapped = data.map((obj) => {
                        return obj._id;
                    });
                    dispatch(success(mapped));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_CATEGORIES_REQUEST } }
    function success(data) { return { type: logsConstants.GET_CATEGORIES_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_CATEGORIES_FAILURE, error } }
}

function getSchema(id,embed){
	return dispatch => {
		dispatch(request());
        
        embedService.getSchema(id,embed)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            )
		
	}
	
    function request() { return { type: logsConstants.GET_SCHEMA_REQUEST } }
    function success(data) { return { type: logsConstants.GET_SCHEMA_SUCCESS, data } }
    function failure(error) { return { type: logsConstants.GET_SCHEMA_FAILURE, error } }
}

function searchLogs(id,query){
    return getLogs(id,{search:query});
}