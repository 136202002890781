import React from 'react';
import { connect } from 'react-redux';
import { AppAlert } from '../../_components';
import { authConstants } from '../../_constants';
import { alertActions, userActions } from '../../_actions';
import Particles from 'react-particles-js';
import ParticleSettings from './particles.json';
import userManager from '../../_helpers/userManager';
import { history } from '../../_helpers';

class LoginPage extends React.Component {
	// componentDidMount() {
	// 	window.addEventListener("message", this.handleFrameTasks);
	// 	this.ifr.onload = () => {
	// 		this.ifr.contentWindow.postMessage("getToken","https://login.m4dsolutions.com");
	// 	}
	// }
	// componentWillUnmount() {
	// 	window.removeEventListener("message", this.handleFrameTasks);
	// }

	errorHappend(){
		this.props.dispatch(alertActions.error("An error occurred, please try again."));
	}

	handleFrameTasks = (e) => {
		if (e.origin !== "https://login.m4dsolutions.com") { return;}
		if(!e.data){this.errorHappend();return;}
		var data = JSON.parse(e.data);
		if(!data){this.errorHappend();return;}
		if(data.status === 1 && data.jwt){
			this.handleLogin(data.jwt);
		}
	}

	handleLogin(jwt){
		// this.props.dispatch(userActions.auth(jwt));
	}

	doLogin = () => {
		userManager.signinRedirect();
	}
    render() {
		const { auth } = this.props;
		if(auth.loggedIn){
			history.push('/');
		}

        return (
			<div id="wrapper" className="login-register">
				<Particles style={{"position": "absolute","top":0,"zIndex":-1000}} params={ParticleSettings}/>
				<div className="login-box">
					<div className="white-box text-center">
						<h3 className="box-title m-lower m-b-20">Welcome to mTracker</h3>
						<AppAlert />
						<button className="btn btn-info btn-block"
							   onClick={this.doLogin}>Login with M4D</button>
					</div>
				</div>
				{/* <iframe title="Login Hook" ref={(f)=> this.ifr = f} style={{display: "none"}} src="https://login.m4dsolutions.com/hook"></iframe> */}
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;
    return {
        auth
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 