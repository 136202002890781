import { createUserManager } from 'redux-oidc';
import { config } from '../config';
import { FakeStateStore } from './fakeStore';

let userManagerConfig = {
  client_id: config.clientID,
  redirect_uri: `${config.app_url}/login/callback`,
  response_type: 'code',
  scope: 'openid profile email',
  authority: config.login_url,
  silent_redirect_uri: `${config.app_url}/login/silent`,
  post_logout_redirect_uri: config.app_url,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
};

if (window.location != window.parent.location) {
  userManagerConfig['userStore'] = new FakeStateStore();
}
let userManager;
try {
  userManager = createUserManager(userManagerConfig);
} catch (e) {
  console.error(e);
}

export default userManager;
