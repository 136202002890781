export const manageConstants = {
    GET_SERVERS_REQUEST: 'MANAGE_GET_SERVERS_REQUEST',
    GET_SERVERS_SUCCESS: 'MANAGE_GET_SERVERS_SUCCESS',
    GET_SERVERS_FAILURE: 'MANAGE_GET_SERVERS_FAILURE',

    ADD_REQUEST: 'MANAGE_ADD_REQUEST',
    ADD_SUCCESS: 'MANAGE_ADD_SUCCESS',
    ADD_FAILURE: 'MANAGE_ADD_FAILURE',

    DELETE_REQUEST: 'MANAGE_DELETE_REQUEST',
    DELETE_SUCCESS: 'MANAGE_DELETE_SUCCESS',
    DELETE_FAILURE: 'MANAGE_DELETE_FAILURE',

    DELETE_USER_REQUEST: 'MANAGE_DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'MANAGE_DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'MANAGE_DELETE_USER_FAILURE',

    USERS_REQUEST: 'MANAGE_USERS_REQUEST',
    USERS_SUCCESS: 'MANAGE_USERS_SUCCESS',
    USERS_FAILURE: 'MANAGE_USERS_FAILURE'
};