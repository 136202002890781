import React from 'react';
import classNames from 'classnames';
class Modal extends React.Component {
    render() {
        const { name, title, children, footer } = this.props;
        var modalClass = classNames({
            "modal-dialog": true,
            "modal-lg": this.props.lg
        });
        return (
            <div>
                <div id={name} className="modal fade" role="dialog" aria-hidden="true">
                    <div className={modalClass}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title">{title}</h4> 
                            </div>
                            <div className="modal-body">
                                {children}
                            </div>
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export {Modal};