import React from 'react';
import { connect } from 'react-redux';
import { Row, ButtonGroup, Button } from 'reactstrap';
import { logActions, embedActions } from '../../_actions'
import ReactTable from 'react-table';

class ServerConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null
        }
        this.onBack = this.onBack.bind(this);
        this.onNext = this.onNext.bind(this);
        this.changeSearch = this.changeSearch.bind(this);
        this.doSearch = this.doSearch.bind(this);

        this.getLogs = this.getLogs.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }

    getLogs(id,options){
        if(this.props.embed){
            return embedActions.getLogs(id,options,this.props.embed);
        }else{
            return logActions.getLogs(id,options);
        }
    }

    getCategories(id){
        if(this.props.embed){
            return embedActions.getCategories(id,this.props.embed);
        }else{
            return logActions.getCategories(id);
        }
    }

    componentDidMount(){
        this.props.dispatch(this.getCategories(this.props.serverID));
        this.props.dispatch(this.getLogs(this.props.serverID));
    }
    timeConverter(UNIX_timestamp){
        var d = new Date(UNIX_timestamp * 1000);
        return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }

    onBack(){
        const { search, category } = this.state;
        const { hasPrevious, previous } = this.props;
        if(!hasPrevious){return;}
        this.props.dispatch(this.getLogs(this.props.serverID,{previous, search, category}));
    }
    onNext(){
        const { search, category } = this.state;
        const { hasNext, next } = this.props;
        if(!hasNext){return;}
        this.props.dispatch(this.getLogs(this.props.serverID,{next, search, category}));
    }
    
    doSearch(currentVal){
        if(this.state.search !== currentVal){return;}
        const { category } = this.state;
        this.props.dispatch(this.getLogs(this.props.serverID,{search:currentVal,category}));
    }

    changeSearch(e){
        var currentVal = e.target.value;
        this.setState({
            search: currentVal
        })
        var searcher = this.doSearch;
        setTimeout(function(){searcher(currentVal)},1500);
    }

    changeCategory(category){
        this.setState({
            search: null,
            category: category
        });
        this.props.dispatch(this.getLogs(this.props.serverID,{category}));
    }

    render() {
        const { results, hasPrevious, hasNext, loading, categories } = this.props;
        const { search } = this.state;
        const cats = categories.map((category)=>{
            return (<Button key={"cat_"+category} color="default" onClick={()=>{this.changeCategory(category)}}>{category}</Button>)
        });
        return (
            <div>
                <Row>
                    <div className="white-box col-lg-12">
                        <h3 className="box-title">Categories <small>Only categories with data are visible</small></h3>
                        <ButtonGroup>
                            <Button color="default" onClick={()=>{this.changeCategory("")}}>All</Button>
                            {cats}
                        </ButtonGroup>
                    </div>
                </Row>
                <Row>
                    <div className="white-box col-lg-12">
                        <h3 className="box-title">Logs</h3>
                        <ReactTable
                            className="-striped -highlight"
                            columns = {[
                                {
                                    Header: "Time",
                                    accessor: "time",
                                    filterable: false,
                                    Cell: row => (
                                        <span>{this.timeConverter(row.value)}</span>
                                    ),
                                    maxWidth: 200
                                },
                                {
                                    Header: "Category",
                                    accessor: "category",
                                    filterable: false,
                                    maxWidth: 200
                                },
                                {
                                    Header: "Log",
                                    accessor: "log",
                                }
                            ]}
                            data={results}
                            defaultPageSize={10}
                            showPagination={false}
                            sortable={false}
                            loading={loading}
                        />
                        <div className="m-t-10">
                            <ButtonGroup>
                                <Button color="info" disabled={!hasPrevious} onClick={this.onBack}>Back</Button>
                                <Button color="info" disabled={!hasNext} onClick={this.onNext}>Next</Button>
                            </ButtonGroup>
                            <span className="pull-right">
                                <input type="text" className="form-control" placeholder="Search" onChange={this.changeSearch} value={search || ""}/>
                            </span>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.logs;
}

const cServerConsole = connect(mapStateToProps)(ServerConsole);
export { cServerConsole as ServerConsole }; 